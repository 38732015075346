<template>
  <div id="alert-info" class="container-fluid mb-0" role="alert">
    <div class="row">
      <div class="col text-center">
        <p>
          <b-icon-info-circle></b-icon-info-circle>
          {{ infoAlert }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import json from "../../assets/content/textboxtexts.json";
export default {
  name: "Alert",
  data() {
    return {
      msg: "Hello",
    };
  },
  computed: {
    roundId: function () {
      return this.$store.state.round - 1;
    },
    infoAlert: function () {
      return json.alerttexts[this.roundId].alerttext;
    },
  },
};
</script>
<style>
#alert-info {
  background-color: #2f5a88;
  padding-top: 10px;
  padding-left: 30px;
  color: white;
}
</style>