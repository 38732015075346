<template>
  <nav class="navbar navbar-expand-md navbar-dark bg-dark mb-0">
    <div class="container-fluid">
      <a href="#" class="navbar-brand mr-3">IAT App</a>
      <b-button
        variant="link"
        @click="showLogs"
        v-show="this.$store.state.showBox === 'start'"
        >Logs</b-button
      >
      <b-button
        variant="link"
        @click="showInfo"
        v-show="this.$store.state.showBox === 'logs'"
        >Zurück</b-button
      >
    </div>
  </nav>
</template>
<script>
// import ButtonMd from "../atoms/ButtonMd.vue";
export default {
  // components: { ButtonMd },
  name: "HeaderNav",
  props: {},
  methods: {
    showLogs() {
      this.$store.state.showBox = "logs";
    },
    showInfo() {
      this.$store.state.showBox = "start";
    },
  },
};
</script>