<template>
  <div>
    <alert v-if="this.$store.state.showBox === 'test'"></alert>
    <iat-page></iat-page>
  </div>
</template>
<script>
// import TestJson from "./components/molecules/TestJson.vue";
import Alert from "../molecules/Alert.vue";
import IatPage from "../templates/IatPage.vue";
export default {
  name: "TestPage",
  components: {
    Alert,
    // TestJson,
    IatPage,
  },
  mounted: function () {},
};
</script>