import Vue from "vue";
import Vuex from "vuex";

//Register Vuex
Vue.use(Vuex);

//Makes store avaialble for app
export const store = new Vuex.Store({
  //State is an object for all properties taht the app has
  state: {
    counter: 0,
    stimcount: 0,
    stimLenghtState: 0,
    buttonIdL: "",
    buttonIdR: "",
    //check for click errors
    error: false,
    currentClick: "",
    stimuliTag: "",
    leftAttribute: "",
    rightAttribute: "",
    leftCategory: "",
    rightCategory: "",
    buttonEnabled: true,
    //generates a random string, not unique, repeats
    //theoretically every 50.000 times  50.000
    //For production replace with UUID
    testId: Math.random()
      .toString(36)
      .substr(4, 8),
    //Stores all logfiles during tests
    logArray: [],
    logResults: [],
    showBox: "start",
    round: 1,
    //data for logfile
    testStatus: "",
    stimuliCode: "",
    stimuli: "",
    buttonClicked: "",
    attributeSide: "",
    result: "",
    congruence: "",
    date: "",
    timerStart: 0,
    timerEnd: 0,
    duration: 0,
  },
  getters: {
    doubleCounter: (state) => {
      return state.counter * 2;
    },
    getButtonIdL: (state) => {
      return state.buttonIdL;
    },
    getButtonIdR: (state) => {
      return state.buttonIdR;
    },
    getStimulus: (state) => {
      return state.stimuli;
    },
    getTime: (state) => {
      return state.now;
    },
  },
  mutations: {
    nextStim: (state) => {
      state.error = false;
      console.log("currentClick: " + state.currentClick);
      console.log("stimuliTag: " + state.stimuliTag);
      // if (state.currentClick === state.stimuliTag) {
      //   alert("correct");
      // } else {
      //   alert("Fehler");
      // }
      //incremenst count while stimuli in array
      if (state.stimcount < state.stimLenghtState - 1) {
        state.stimcount++;
      } else if (state.round >= 5) {
        state.showBox = "end";
      } else {
        state.round++;
        state.stimcount = 0;
        state.showBox = "info";
      }
    },
    // the next ones are just for testing
    increment: (state) => {
      state.counter++;
    },
    decrement: (state) => {
      state.counter--;
    },
    decrementPayload: (state, payload) => {
      state.counter -= payload;
    },
    updatestimLenghtState: (state, value) => {
      state.stimLenghtState = value;
    },
    incrementRound: (state) => {
      state.round++;
      // console.log("Round (store): " + state.round);
    },
    setButtonIdL: (state, payload) => {
      state.buttonIdL = payload;
    },
    setButtonIdR: (state, payload) => {
      state.buttonIdR = payload;
    },
    setStimuliWord: (state, word) => {
      state.stimuli = word;
    },
    setStimuliCode: (state, code) => {
      state.stimuliCode = code;
    },
    setStimuliTag: (state, tag) => {
      state.stimuliTag = tag;
    },
    setButtonCLicked: (state, click) => {
      state.buttonClicked = click;
    },
    setLeftAttribute: (state, attL) => {
      state.leftAttribute = attL;
      console.log("state.leftAttribute: " + state.leftAttribute);
    },
    setRightAttribute: (state, attR) => {
      state.rightAttribute = attR;
      console.log("state.rightAttribute: " + state.rightAttribute);
    },
    setLeftCategory: (state, leftC) => {
      state.leftCategory = leftC;
      console.log("state.leftCategory: " + state.leftCategory);
    },
    setRightCategory: (state, rightC) => {
      state.rightCategory = rightC;
      console.log("state.rightCategory: " + state.rightCategory);
    },
    changeErrorStatus: (state, payload) => {
      state.error = payload;
    },
    setCurrentClick: (state, click) => {
      state.currentClick = click;
    },
    changeButtonStatus: (state, payload) => {
      state.buttonEnabled = payload;
    },
    setTimerStart: (state, now) => {
      state.timerStart = now;
    },
    setTimerEnd: (state, now) => {
      state.timerEnd = now;
    },
    setDuration: (state) => {
      state.duration = Math.round(state.timerEnd - state.timerStart);
    },
    setCongruenceState: (state, payload) => {
      state.congruence = payload;
    },
    pushLogarray: (state, payload) => {
      // state.logArray += "," + payload;
      state.logArray.push(payload);
      // state.logArray.append(payload);
    },
    setDate: (state) => {
      state.date = new Date().toISOString();
    },
    setTestStatus: (state, payload) => {
      state.testStatus = payload;
    },
    setAttributeSide: (state, payload) => {
      state.attributeSide = payload;
    },
    setResult: (state, payload) => {
      state.result = payload;
    },
    pushResults: (state, payload) => {
      state.logResults.push(payload);
    },
  },
  actions: {
    decrement: ({ commit }) => {
      commit("decrement");
    },
    decrementPayload: ({ commit }, payload) => {
      commit("decrementPayload", payload);
    },
  },
});
