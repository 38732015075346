// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import Vue from "vue";
import App from "./App.vue";
// JSOn to CSV Export
import JsonCSV from "vue-json-csv";

//Import Vuex store
import { store } from "./store/store.js";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "./registerServiceWorker";
//Normalizes CSS see css file for source
import "./assets/stylesheets/normalize.css";

//Vue Axios
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);
// JSON to CSV Export
Vue.component("downloadCsv", JsonCSV);
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.config.productionTip = false;

new Vue({
  store,
  render: (h) => h(App),
}).$mount("#app");
